<template>
  <v-container>
    <v-overlay :value="loadingData" opacity="0.85">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-btn text color="accent" @click="$router.go(-1)" class="mb-2">
      <v-icon left> mdi-arrow-left-thick </v-icon>
      Back
    </v-btn>
    <p class="text-subtitle-1 font-weight-bold">
      Got questions? Call or Whatsapp {{ siteData.phone }}
    </p>
    <v-card-title>
      {{ cryptoData.name }}
    </v-card-title>
    <v-card-text>
      Please note: snappyexchange.com is NOT a football/lottery/Flight ticketing
      agent or an investment scheme. If anyone directed you to our website
      please be aware, we are digital currency exchanger, and we are not
      affiliated or in partnership with any entity. If you are on this page,
      please be informed that digital currency funding is an irreversible
      process. In case you find yourself on this page, and you don't understand
      what we do or you were being directed here by an unknown entity, please
      call ,
      <a
        class="text-decoration-none font-weight-bold"
        href="tel:+2348156-010101"
        >+2348156-010101</a
      >
      for more clarification also note that we don't fund third party account.
    </v-card-text>
    <v-card-text class="font-weight-medium" v-if="cryptoData.buy === 0">
      We presently don't buy {{ cryptoData.name }}
    </v-card-text>
    <v-card-text class="font-weight-medium" v-if="cryptoData.sell === 0">
      We presently don't sell {{ cryptoData.name }}
    </v-card-text>

    <v-dialog scrollable v-model="buyDialog" max-width="700">
      <v-sheet>
        <v-stepper v-model="buyProgress">
          <v-stepper-header>
            <v-stepper-step :complete="buyProgress > 1" step="1">
              Read
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="buyProgress > 2" step="2">
              Confirm Details
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">Upload Confirmation</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-container>
                <v-card-title>
                  READ BEFORE YOU DEPOSIT MONEY INTO OUR BANK ACCOUNT
                </v-card-title>
                <v-card-text>
                  <p>
                    Do not pay below the
                    {{ numberWithCommas(Number(totalBuyPayable)) }}
                    {{ userCurrency }}. you can pay above the amount but we do
                    not refund change.
                  </p>
                  <p>
                    To avoid Transaction issue, please transfer from your own
                    internet banking app or cash deposit via the bank with your
                    registered name. i.e. the name you registered with on our
                    website. e.g If your registered name on our website is "ALIM
                    OLAIDE", you need to use "ALIM OLAIDE" as the Depositor Name
                    if you are making payment via cash deposit or as a
                    remark/memo,naration,description,purpose if you are making
                    payment via internet transfer, mobile transfer.
                  </p>
                  <p>
                    Payment must originate from your own bank account bearing
                    the same registered names with SnappyEXchange.
                  </p>
                  <p>
                    Third-party payment via internet transfer, mobile transfer
                    and cash deposit are not allowed. E.g. If your registered
                    name on SNappy exchange is "ALIM OLAIDE" and you use Abass
                    Lawal to send money to our account, we will report the
                    payment and your account will be frozen.
                  </p>
                  <p>
                    Meaning we do not allow any payment that did not come from
                    an account that doesn't belong to you. Third-party payment
                    will be reported to the police and your snappy account will
                    be automatically frozen.
                  </p>
                  <p>
                    When sending Money to our bank account, please make sure you
                    write your name in the payment
                    purpose/description/narration.
                  </p>
                  <p>
                    Please don’t type bitcoin, BTC, ETH, Perfect money, PM,
                    Crypto, Deriv, Forex in your transfer to us.
                  </p>
                  <p>If you violate this term, your account will be BLOCKED.</p>
                  <p>
                    Snappy-eXchange will not be RESPONSIBLE for funding a wrong
                    ACCOUNT or WALLET provided by you.
                  </p>
                  <p class="primary--text">
                    By proceeding to pay into our bank account, you agree to
                    these terms.
                  </p>
                </v-card-text>
              </v-container>
              <v-btn color="primary" @click="buyProgress = 2"> Continue </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-container>
                <v-card flat>
                  <v-card-title> Currency Summary </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col
                        v-if="
                          paymentMethods.find(
                            (m) => m.name.toLowerCase() === 'wallet'
                          )
                            ? paymentMethods.find(
                                (m) => m.name.toLowerCase() === 'wallet'
                              )._id !== buyData.paymentMethodId
                            : false
                        "
                        cols="12"
                        md="4"
                        class="mb-4"
                      >
                        <h3 class="mb-1">Bank Payment Details</h3>
                        <div class="body-1">
                          <p>{{ selectedBank.accname }}</p>
                          <p>{{ selectedBank.account }}</p>
                          <p>{{ selectedBank.name }}</p>
                        </div>
                      </v-col>
                      <v-col cols="12" md="4">
                        <p class="overline mb-1">Amount in USD</p>
                        <span class="font-weight-bold"
                          >{{ buyData.amount }} USD</span
                        >
                      </v-col>
                      <v-col cols="12" md="8">
                        <span class="overline mb-1"
                          >Amount in {{ userCurrency }} (+{{
                            siteData.transcharge
                          }}% VAT) (+{{ tCharge }} charge)</span
                        >
                        <span class="font-weight-bold primary--text">
                          {{ numberWithCommas(Number(totalBuyPayable)) }}</span
                        >
                        <br />
                        <span class="overline mb-1">Amount</span>
                        <span class="font-weight-bold"
                          >$1.00 = {{ cryptoData.buy }} {{ userCurrency }}</span
                        >
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="overline mb-1">
                          {{ cryptoData.name }} Wallet Address
                        </p>
                        <span class="font-weight-bold">{{
                          buyData.wallet
                        }}</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
              <v-btn
                v-if="buyTransactionType === 'wallet'"
                color="primary"
                :loading="loadingBuy"
                @click="buyCoin"
              >
                Finish
              </v-btn>
              <v-btn v-else color="primary" @click="buyProgress = 3">
                Continue
              </v-btn>
              <v-btn
                text
                @click.stop="
                  buyDialog = false
                  buyProgress = 1
                "
              >
                Cancel
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-container>
                <v-card flat>
                  <v-row>
                    <!-- <v-col cols="12">
                      <v-text-field
                        v-model="buyData.depositor"
                        label="Depositor's Name"
                      ></v-text-field>
                    </v-col> -->
                    <v-col cols="12">
                      <v-file-input
                        clearable
                        filled
                        counter
                        v-model="buyData.image"
                        label="Upload file"
                        hint="Upload Payment Screenshot"
                        persistent-hint
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-card>
              </v-container>

              <v-btn color="primary" :loading="loadingBuy" @click="buyCoin">
                Finish
              </v-btn>

              <v-btn
                text
                @click.stop="
                  buyDialog = false
                  buyProgress = 1
                "
              >
                Cancel
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-sheet>
    </v-dialog>

    <v-dialog scrollable v-model="sellDialog" max-width="700">
      <v-sheet>
        <v-stepper v-model="sellProgress">
          <v-stepper-header>
            <v-stepper-step :complete="sellProgress > 1" step="1">
              Read
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="sellProgress > 2" step="2">
              Confirm Details
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">Upload Confirmation</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-container>
                <v-card-title> READ BEFORE YOU PROCEED WITH SALE </v-card-title>
                <v-card-text>
                  <p>
                    Amount Receivable
                    <b>{{ sellPriceWithBonus }} {{ currency }}</b>
                  </p>
                  <p>
                    Do not send below the required amount of
                    <b> USD {{ sellData.amount }}.</b>
                  </p>
                  <!-- <p>
                    Snappy-eXchange will not be responsible for funding a wrong
                    account number provided by you
                  </p> -->
                  <p class="primary--text">
                    By proceeding to sell on our website, you agree our terms
                    and condition
                  </p>
                </v-card-text>
              </v-container>
              <v-btn color="primary" @click="sellProgress = 2">
                Continue
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-container>
                <v-card flat>
                  <v-card-text>
                    Please transfer
                    <span class="primary--text font-weight-bold"
                      >{{ sellData.amount }} USD worth of
                      {{ cryptoData.symbol || cryptoData.displaySymbol }}</span
                    >
                    to the
                    {{
                      cryptoData.type === 'wallet'
                        ? 'Wallet Address'
                        : 'Account'
                    }}
                    below. Please note, do not send below
                    {{ sellData.amount }} USD worth of
                    {{ cryptoData.symbol || cryptoData.displaySymbol }}. We only
                    credit what you send
                  </v-card-text>
                  <p class="mb-0 ml-4">Wallet Address</p>
                  <v-card-title>
                    <span class="primary--text font-weight-bold">{{
                      cryptoData.type === 'wallet'
                        ? sellNetworkDetails.wallet
                        : `${cryptoData.prefix}${cryptoData.account_number}`
                    }}</span
                    ><v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-on="on"
                          v-bind="attrs"
                          @click="
                            copy(() => {
                              return cryptoData.type === 'wallet'
                                ? sellNetworkDetails.wallet
                                : `${cryptoData.prefix}${cryptoData.account_number}`
                            })
                          "
                          >mdi-content-copy</v-icon
                        >
                      </template>
                      Copy
                    </v-tooltip>
                  </v-card-title>
                </v-card>
                <v-card flat>
                  <v-card-title class="mb-1 pb-1 accent--text">
                    Summary
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" v-if="cryptoData.type === 'wallet'">
                        <p class="overline mb-1">
                          Your can also scan code to make payment
                        </p>
                        <v-img
                          :src="sellNetworkDetails.barcode"
                          alt="sellNetworkDetails.wallet"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="overline mb-1">Amount in USD</p>
                        <span class="font-weight-bold"
                          >{{ sellData.amount }} USD</span
                        >
                      </v-col>
                      <v-col cols="12" md="6">
                        <span class="overline mb-1"
                          >Amount in {{ userCurrency }}</span
                        >
                        <span class="font-weight-bold primary--text">
                          {{ sellPrice }} {{ currency }}
                        </span>
                        <br />
                        <span class="overline mb-1">Amount </span>
                        <span class="font-weight-bold"
                          >$1.00 = {{ exchangeRate() }}
                        </span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="subtitle">
                          Money will deposited into your Snappy eXchange wallet
                        </p>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="overline mb-1">
                          {{ cryptoData.name }}
                          {{
                            cryptoData.type === 'wallet'
                              ? 'Wallet Address'
                              : 'Account'
                          }}
                        </p>
                        <span class="font-weight-bold">{{
                          cryptoData.type === 'wallet'
                            ? sellNetworkDetails.wallet
                            : `${cryptoData.prefix}${cryptoData.account_number}`
                        }}</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>

              <v-btn color="primary" @click="sellProgress = 3">
                Continue
              </v-btn>
              <v-btn @click.stop="sellDialog = false" text> Cancel </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-container>
                <v-card flat>
                  <v-row>
                    <v-col cols="12">
                      <v-card-text>
                        If you have paid to our {{ cryptoData.name }}
                        {{
                          cryptoData.type === 'wallet'
                            ? 'Wallet Address'
                            : 'Account'
                        }}, upload a screenshot of your successful payment as
                        proof.
                      </v-card-text>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input
                        clearable
                        filled
                        counter
                        v-model="sellData.image"
                        label="Upload payment screenshot"
                        hint="Upload a screenshot of your successful payment as proof."
                        persistent-hint
                      ></v-file-input>
                    </v-col>
                    <v-card-text>
                      <p class="info--text">
                        Do not proceed with this process if you have not made
                        your payment
                      </p>
                      <p class="error--text">
                        In case you sent a different amount, send us a message,
                        Snappy-eXchange will update accordingly.
                      </p>
                    </v-card-text>
                  </v-row>
                </v-card>
              </v-container>

              <v-btn
                color="primary"
                :disabled="!sellData.image"
                :loading="loadingSell"
                @click="sellCoin"
              >
                Finish
              </v-btn>

              <v-btn text @click="sellDialog = false"> Cancel </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-sheet>
    </v-dialog>
    <v-row>
      <v-col cols="12" md="6">
        <v-card rounded="lg"
          ><v-tabs v-model="tradeTab">
            <v-tab @click="toAutoIfAuto" :disabled="cryptoData.sell === 0"
              >Sell</v-tab
            >
            <v-tab :disabled="cryptoData.buy === 0">Buy</v-tab>
          </v-tabs>
          <v-container>
            <v-tabs-items v-model="tradeTab">
              <!-- Sell -->
              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      Amount
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="sellData.amount"
                            filled
                            suffix="USD"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            filled
                            readonly
                            v-model="sellPrice"
                            :suffix="currency"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            filled
                            v-model="sellData.network"
                            :items="cryptoData.networks"
                            item-text="name"
                            item-value="_id"
                            :label="`Select your ${
                              cryptoData.symbol ||
                              cryptoData.displaySymbol ||
                              cryptoData.name
                            } Network`"
                          ></v-autocomplete>
                        </v-col>

                        <!-- <v-col cols="12">
                          <v-text-field
                            filled
                            v-model="sellData.accountNumber"
                            label="Account Number"
                            :disabled="!sellData.bank"
                            type="number"
                          ></v-text-field>
                        </v-col> -->
                        <!-- <v-col cols="12">
                          <v-text-field
                            filled
                            v-model="sellData.acoountname"
                            label="Account Name"
                            :disabled="!sellData.bank"
                          ></v-text-field>
                        </v-col> -->
                      </v-row>

                      <v-text-field
                        filled
                        v-model="promoCode"
                        label="Promo Code"
                        prepend-icon="mdi-help"
                        @click:prepend="aboutPromoCode"
                      >
                        <template v-slot:append-outer>
                          <v-btn
                            color="primary"
                            @click="applyPromoCode"
                            :loading="loadingPromoCode"
                            :disabled="!promoCode"
                          >
                            Apply
                          </v-btn>
                        </template>
                      </v-text-field>

                      <div class="text-center">
                        <span class="primary--text overline text-h6"
                          >EXCHANGE {{ exchangeRate() }}/$</span
                        >
                      </div>
                      <v-btn
                        @click.stop="sellDialog = true"
                        class="mt-3"
                        block
                        large
                        :disabled="sellData.amount == '' || !sellData.network"
                        color="primary"
                        >SELL NOW</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <!-- Buy -->
              <v-tab-item>
                <v-container
                  v-if="
                    userCountry === COUNTRIES.GH || userCountry === COUNTRIES.KE
                  "
                >
                  <p class="text-center">
                    YOU CANT BUY CRYPTO FROM US AT THE MOMENT
                  </p>
                </v-container>

                <v-container v-else-if="user.kycverify === false">
                  <p class="text-center">
                    You can't buy Crypto from us because you haven't done KYC
                    verification yet.
                    <router-link to="/user/settings?tab=4">
                      Click here </router-link
                    >to start KYC Verification
                  </p>
                </v-container>

                <v-container v-else>
                  <v-row>
                    <v-col cols="12">
                      <v-card color="primary white--text">
                        <v-card-title class="py-1">Wallet</v-card-title>
                        <v-card-text class="white--text">
                          <p
                            style="font-size: 12px"
                            class="opacity-85 text-uppercase mb-0"
                          >
                            BALANCE
                          </p>
                          <p class="text-h6 mb-0">
                            {{ userCurrency }} {{ wallet.balance || 0 }}
                          </p>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-card-text class="font-we">
                      Please note that money will be removed from your wallet
                      for this transaction
                    </v-card-text>
                    <v-col cols="12">
                      <v-select
                        filled
                        v-model="buyData.paymentMethodId"
                        :loading="paymentMethods.length == 0"
                        :items="paymentMethods"
                        hint="Select payment method"
                        item-text="name"
                        item-value="_id"
                        label="Payment Method"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        filled
                        v-model="buyData.networkId"
                        :items="cryptoData.networks"
                        item-text="name"
                        item-value="_id"
                        :label="`Select your ${
                          cryptoData.symbol ||
                          cryptoData.displaySymbol ||
                          cryptoData.name
                        } Network`"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :prefix="cryptoData.prefix"
                        v-model="buyData.wallet"
                        filled
                      >
                        <template v-slot:label>
                          <span>
                            <span class="text-capitalize font-weight-bold">
                              {{
                                cryptoData.name
                                  ? cryptoData.name.toLowerCase()
                                  : ''
                              }}
                            </span>
                            {{
                              cryptoData.type === 'wallet'
                                ? 'Wallet address'
                                : 'Account'
                            }}
                          </span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      Amount
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="buyData.amount"
                            filled
                            suffix="USD"
                            type="number"
                            :rules="[
                              (v) =>
                                (v &&
                                  Number(v) >=
                                    `${Number(cryptoData.miniorder)}`) ||
                                `Minimum buy for ${
                                  cryptoData.symbol || cryptoData.displaySymbol
                                } is ${cryptoData.miniorder} `
                            ]"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            filled
                            readonly
                            v-model="buyPrice"
                            :suffix="userCurrency"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          v-if="
                            paymentMethods.find(
                              (m) => m.name.toLowerCase() === 'wallet'
                            )
                              ? paymentMethods.find(
                                  (m) => m.name.toLowerCase() === 'wallet'
                                )._id !== buyData.paymentMethodId
                              : false
                          "
                          cols="12"
                        >
                          <v-select
                            filled
                            v-model="buyData.bank"
                            :loading="banks.length == 0"
                            :items="banks"
                            hint="Select Preferred Account to Pay Into"
                            persistent-hint
                            item-text="name"
                            item-value="_id"
                            label="Choose Bank "
                          ></v-select>
                        </v-col>
                      </v-row>

                      <div class="text-center">
                        <span class="primary--text overline text-h6"
                          >EXCHANGE {{ exchangeRate() }}/$</span
                        >
                      </div>
                      <!-- <div v-if="buyPrice >= wallet.balance">
                        <v-card-text class="error--text">
                          You can't buy above what you have in your wallet,
                          kindly make a deposit, you need
                          <span class="font-weight-bold"
                            >{{ buyPrice - (wallet.balance || 0) }} NGN</span
                          >
                          more
                        </v-card-text>
                        <v-btn
                          color="primary"
                          to="/user/wallet/deposit"
                          class="mr-5"
                          >Deposit</v-btn
                        >
                      </div> -->

                      <v-row class="mt-3">
                        <v-col cols="12">
                          <v-btn
                            @click.stop="handleBuyNowClick"
                            :disabled="
                              buyData.amount == '' ||
                              buyData.wallet == '' ||
                              !buyData.networkId
                            "
                            block
                            large
                            color="primary"
                            >BUY NOW</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card rounded="md" flat class="animate__animated animate__zoomIn">
          <v-container>
            <v-card-text v-if="tradeTab == 0">
              <p class="info--text">
                The sell order will be approved after your transaction has been
                confirmed and approved on our server..
              </p>
              <v-card-subtitle
                >Money will be added into your
                <span class="primary--text"> Snappy eXchange</span>
                wallet</v-card-subtitle
              >
            </v-card-text>
            <v-card-text v-if="tradeTab == 1">
              <p class="info--text">
                The purchased will appear in your {{ cryptoData.name }} account
                only after you transaction has been confirmed and approved on
                our server..
              </p>

              <p class="error--text">
                Ensure you enter a valid {{ cryptoData.name }} Wallet address
                Snappy-eXchange will not be liable for any loss arising from you
                entering a wrong {{ cryptoData.name }} Wallet address.
              </p>
            </v-card-text>

            <v-card
              flat
              rounded="md"
              :color="tradeTab == 1 ? 'teal' : 'blue'"
              class="lighten-4 my-2 px-3"
            >
              <v-card-text class="pa-2">
                <v-row>
                  <v-col cols="6" class="text-left black--text subtitle-2"
                    >YOU ARE {{ tradeTab == 1 ? 'BUYING' : 'SELLING' }}</v-col
                  >
                  <v-col cols="6" class="text-right black--text subtitle-2"
                    >$ {{ tradeTab == 1 ? buyData.amount : sellData.amount }}
                    {{ cryptoData.symbol || cryptoData.displaySymbol }}</v-col
                  >
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              flat
              rounded="md"
              :color="tradeTab == 1 ? 'teal' : 'blue'"
              class="lighten-4 my-2 px-3"
            >
              <v-card-text class="pa-2">
                <v-row>
                  <v-col cols="4" class="text-left black--text subtitle-2"
                    >YOU {{ tradeTab == 1 ? 'PAY' : 'GET' }}</v-col
                  >
                  <!-- <v-col
                    v-if="promoCodeBonus"
                    cols="8"
                    class="text-right black--text subtitle-2"
                    >{{ currency }}
                    {{
                      tradeTab == 1
                        ? numberWithCommas(Number(buyPrice) + vat + tCharge)
                        : sellPrice
                    }}
                    {{ tradeTab == 1 ? `(+7.5% VAT)` : currency }}</v-col
                  > -->
                  <v-col cols="8" class="text-right black--text subtitle-2"
                    >{{ currency }}
                    {{ tradeTab == 1 ? totalBuyPayable : sellPrice }}
                    {{
                      tradeTab == 1
                        ? `(+7.5% VAT) (+${tCharge} Charge)`
                        : currency
                    }}</v-col
                  >
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              flat
              color="blue"
              rounded="md"
              v-if="promoCodeBonus"
              class="lighten-4 my-2 px-3"
            >
              <v-card-text class="pa-2">
                <v-row>
                  <v-col cols="4" class="text-left black--text subtitle-2"
                    >BONUS</v-col
                  >
                  <v-col cols="8" class="text-right black--text subtitle-2"
                    >{{ promoCodeBonus }} {{ currency }}</v-col
                  >
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              flat
              color="blue"
              rounded="md"
              v-if="promoCodeBonus"
              class="lighten-4 my-2 px-3"
            >
              <v-card-text class="pa-2">
                <v-row>
                  <v-col cols="4" class="text-left black--text subtitle-2"
                    >TOTAL</v-col
                  >
                  <v-col cols="8" class="text-right black--text subtitle-2"
                    >{{ sellPriceWithBonus }} {{ currency }}</v-col
                  >
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              flat
              rounded="md"
              :color="tradeTab == 1 ? 'teal' : 'blue'"
              class="lighten-4 my-2 px-3"
            >
              <v-card-text class="pa-2">
                <v-row>
                  <v-col cols="6" class="text-left black--text subtitle-2"
                    >RATE</v-col
                  >
                  <v-col cols="6" class="text-right black--text subtitle-2"
                    >{{ exchangeRate() }}/$
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
          <v-container v-if="tradeTab == 1">
            <v-divider class="py-3"></v-divider>
            <v-card flat>
              <v-card-subtitle
                >Money gets removed from your
                <span class="primary--text"> Snappy eXchange</span>
                wallet</v-card-subtitle
              >
            </v-card>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dataApi from '../../../../api/systemData'
import cryptoCoin from '../../../../api/users/cryptocoin'
import { numberWithCommas } from '../../../../utils/helpers'
import { mapState } from 'vuex'
import promoCodes from '../../../../api/promo-codes'
import { COUNTRIES, getCurrencySymbol } from '../../../../utils/countryHelpers'

export default {
  components: {},
  data() {
    return {
      COUNTRIES,
      disabledSell: false,
      cryptoId: '',
      makeDeposit: false,
      cryptoData: {},
      buyDialog: false,
      sellDialog: false,
      loadingSell: false,
      loadingBuy: false,
      tradeTab: 1,
      paymentMethods: [],
      loading: false,
      buyProgress: 1,
      sellProgress: 1,
      loadingData: false,
      banks: [],
      sellData: {
        amount: '',
        price: '',
        bank: ''
      },
      buyData: {
        paymentMethod: '',
        wallet: '',
        amount: '',
        price: ''
      },
      numberWithCommas,
      localBanks: [],
      buyTransactionType: 'wallet',

      promoCode: '',
      loadingPromoCode: false,
      promoCodeBonus: 0
    }
  },
  watch: {
    tradeTab(val) {
      if (val === 1) {
        this.promoCodeBonus = 0
      }
      if (val === 0 && this.disabledSell) {
        // this.$router.push(`/user/products/crypto/auto-trade/${this.cryptoId}`)
        this.tradeTab = 1
      }
    },
    sellPrice() {
      this.promoCodeBonus = 0
      if (this.promoCode !== '') {
        this.applyPromoCode()
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      wallet: (state) => state.user.wallet,
      siteData: (state) => state.user.siteSettings,
      countrySetting: (state) => state.user.countryCurrency
    }),
    sellNetworkDetails() {
      return (
        this.cryptoData.networks.find(
          (network) => network._id === this.sellData.network
        ) || {}
      )
    },
    selectedBank() {
      if (this.buyData.bank) {
        return this.banks.find((bank) => bank._id === this.buyData.bank)
      }
      return {}
    },
    // FIXME Why this there a * 5 here?
    vat() {
      if (this.buyData.amount) {
        const transcharge = this.siteData.transcharge
        return (Number(transcharge) / 100) * (this.buyData.amount * 5)
      }
      return 0
    },
    tCharge() {
      return Number(this.cryptoData.tcharge)
    },
    totalBuyPayable() {
      return Number(this.buyPrice) + this.vat + this.tCharge
    },
    buyPrice() {
      if (this.buyData.amount) {
        const v =
          (this.cryptoData.buy * this.buyData.amount) /
          this.countrySetting?.[this.userCountry.toLowerCase()].nairaRate
        return Number(Number(v).toFixed(2))
      }
      return 0
    },
    sellPriceBase() {
      if (this.sellData.amount) {
        return Number(this.cryptoData.sell) * Number(this.sellData.amount)
      }
      return 0
    },
    sellPrice() {
      if (this.sellData.amount) {
        return numberWithCommas(
          Number(
            (this.cryptoData.sell * this.sellData.amount) /
              (this.countrySetting?.[this.userCountry.toLowerCase()]
                .nairaRate || 1)
          ).toFixed(2)
        )
      }
      return 0
    },
    sellPriceWithBonus() {
      if (this.sellData.amount) {
        return numberWithCommas(
          Number(
            Number(
              (this.cryptoData.sell * this.sellData.amount) /
                (this.countrySetting?.[this.userCountry.toLowerCase()]
                  .nairaRate || 1)
            ).toFixed(2)
          ) + Number(this.promoCodeBonus)
        )
      }
      return 0
    },
    needsBank() {
      if (this.buyData.paymentMethodId) {
        const selected = this.paymentMethods.find(
          (p) => p._id === this.buyData.paymentMethodId
        )
        if (selected.name.toLowerCase() === 'wallet') return false
        else {
          if (this.buyData.bank === undefined) return true
          else return false
        }
      } else return true
    },
    exchangeRate() {
      return () => {
        return this.tradeTab === 1
          ? `${getCurrencySymbol(this.userCountry.toLowerCase())} ${Number(
              this.cryptoData.buy /
                (this.countrySetting?.[this.userCountry?.toLowerCase()]
                  .nairaRate || 1)
            ).toFixed(2)}`
          : `${getCurrencySymbol(this.userCountry.toLowerCase())} ${Number(
              this.cryptoData.sell /
                (this.countrySetting?.[this.userCountry?.toLowerCase()]
                  .nairaRate || 1)
            ).toFixed(2)}`
      }
    },
    currency() {
      return getCurrencySymbol(this.userCountry.toLowerCase())
    }
  },
  mounted() {
    this.disabledSell = this.$route.query.XswQa === 'true'
    this.tradeTab = this.disabledSell ? 1 : 0
  },
  created() {
    this.cryptoId = this.$route.params.id
    this.getCurrencyData()
    this.getPaymentMethods()
    this.getBanks()
    this.getLocalBanks()
  },
  methods: {
    toAutoIfAuto() {
      if (this.disabledSell) {
        this.$router.push(`/user/products/crypto/auto-trade/${this.cryptoId}`)
      }
    },
    handleBuyNowClick() {
      let paymentName = this.paymentMethods.find(
        (m) => m._id === this.buyData.paymentMethodId
      )
      if (paymentName) paymentName = paymentName.name.toLowerCase()
      this.buyTransactionType = paymentName
      if (paymentName === 'wallet') {
        this.buyProgress = 2
        this.buyDialog = true
      } else if (paymentName === 'bank transfer') {
        this.payProgress = 1
        this.buyDialog = true
      }
      this.payProgress = 1
      this.buyDialog = true
    },
    copy(copyFu) {
      const input = document.createElement('input')
      input.value = copyFu()

      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      this.$store.dispatch('alert', { message: 'Copied!', time: 2000 })
    },
    changeBuyFiles(image) {
      // console.log('New picture selected!')
      if (image) {
        // console.log('Picture loaded.')
        this.image = image
      } else {
        // console.log('FileReader API not supported: use the <form>, Luke!')
      }
    },
    async getCurrencyData() {
      this.loadingData = true
      const res = await dataApi.data().getSingleData('user', {
        type: 'currency',
        typeId: this.cryptoId,
        params: {
          populate: ['networks']
        }
      })
      this.cryptoData = res.data.data
      this.tradeTab =
        this.cryptoData.sell !== 0 && this.cryptoData.buy !== 0
          ? null
          : this.cryptoData.sell === 0
          ? 1
          : this.cryptoData.buy === 0
          ? 0
          : null
      this.loadingData = false
    },
    async getPaymentMethods() {
      const res = await dataApi
        .data()
        .getData('user', { type: 'paymentmethod' })
      this.paymentMethods = res.data.data.data.filter((m) => m.status)
    },
    async getBanks() {
      const res = await dataApi.data().getData('user', { type: 'bank' })
      this.banks = res.data.data.data.filter((bank) => bank.status)
    },
    async getLocalBanks() {
      const res = await dataApi.data().getData('user', { type: 'localbank' })
      this.localBanks = res.data.data.data.reverse()
    },
    async buyCoin() {
      this.loadingBuy = true
      const res = await cryptoCoin.cryptoCoin().buy(this.cryptoId, {
        wallet: this.buyData.wallet,
        amount: this.buyData.amount,
        image: this.buyData.image,
        paymentMethodId: this.buyData.paymentMethodId,
        networkId: this.buyData.networkId
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loadingBuy = false
        return
      }
      this.$store.dispatch('alert', { message: 'Successful!', status: true })
      this.buyDialog = false
      this.buyData = {}
      this.loadingBuy = false
      this.$router.push('/user/dashboard')
    },
    async sellCoin() {
      this.loadingSell = true
      const res = await cryptoCoin.cryptoCoin().sell(this.cryptoId, {
        image: this.sellData.image,
        amount: this.sellData.amount,
        promoCode: this.promoCode
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loadingSell = false
        return
      }
      this.$store.dispatch('alert', { message: 'Successful!', status: true })
      this.sellDialog = false
      this.sellData = {}
      this.loadingSell = false
      this.$router.push('/user/dashboard')
    },
    async applyPromoCode() {
      this.loadingPromoCode = true
      const res = await promoCodes.promoCodes().calculateCodeBonus({
        promoCode: this.promoCode.trim(),
        productType: 'currency',
        amount: Number(this.sellPriceBase.toString().replace(/,/g, '')),
        productId: this.cryptoId
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message: res.errorMessage.message,
          error: true,
          status: true
        })
        this.$swal.fire({
          icon: 'error',
          title: 'Unable to apply code',
          text: res.errorMessage.message
        })
        this.loadingPromoCode = false
        return
      }

      if (res.data?.data?.discountValue) {
        this.promoCodeBonus = res.data.data.discountValue

        this.promoCodeBonus = Number(Number(this.promoCodeBonus).toFixed(2))
      } else {
        this.promoCodeBonus = 0
        this.$swal.fire({
          icon: 'error',
          title: 'Unable to apply code',
          text: res.data.data
        })
      }

      this.loadingPromoCode = false
    },
    async aboutPromoCode() {
      this.$swal.fire({
        icon: 'info',
        title: 'About Promo Codes',
        text:
          'Do you have a promo code? Enter it here to see if you qualify for a trade bonus. If you do not have any, leave it empty. To see available promo codes, check the Promo codes page on our Blog  or follow Snappy Exchange on Instagram @SnappyExchangeNg'
      })
    }
  }
}
</script>

<style>
.info-i {
  font-size: 0.8rem;
  color: #fff;
  background-color: rgb(32, 100, 210);
  cursor: pointer;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-style: italic;
  display: flex;
  justify-items: center;
  align-items: center;
}
</style>
