import axios from 'axios'
import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/cryptocoin`

export default {
  cryptoCoin() {
    return {
      buy: async (
        coinId,
        { wallet, amount, image, paymentMethodId, networkId }
      ) => {
        const endpoint = `${URL}/${coinId}/buy`

        const formData = new FormData()

        formData.append('image', image)
        formData.append('wallet', wallet)
        formData.append('amount', amount)
        formData.append('paymentMethodId', paymentMethodId)
        if (networkId) {
          formData.append('networkId', networkId)
        }

        try {
          const res = await axios.post(endpoint, formData, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'content-type': 'multipart/form-data'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      sell: async (coinId, { image, amount, promoCode }) => {
        const endpoint = `${URL}/${coinId}/sell`

        const formData = new FormData()
        formData.append('image', image)
        formData.append('amount', amount)
        if (promoCode) {
          formData.append('promoCode', promoCode)
        }

        try {
          const res = await axios.post(endpoint, formData, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'content-type': 'multipart/form-data'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
